.header {
  display: flex;
  flex-direction: column;
  padding: 32px 30px;
  /* margin-bottom: 30px;*/
}

.intro {
    font-size: 1.2rem;
    font-weight: 300;
    text-align: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    text-shadow: 0 0 6px white;
    color: lightgray;
    // top: -54px;
    opacity: 0;
    animation: appear 8s 7s forwards;

    span {
      color: #00ffffb0;
      font-weight: 700;
      text-shadow: 0 0 11px #87f5fb;
    }

}

.hi {
    position: absolute;
    top: 105px;
    left: 0px;
    right: 0;
    text-align: center;
    opacity: 0;
    animation: disappear 1.3s 2.5s forwards ;
  }
  
  // .im {
  //   position: absolute;
  //   top: 105px;
  //   left: 0px;
  //   right: 0;
  //   text-align: center;
  //   opacity: 0;
  //   animation: disappear 4.5s 3.5s forwards;
  // }
  
  .david {
    /* top: 0px; */
    right: 83px;
    color: white;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 4px;
    text-shadow: 0 0 12px white;
    opacity: 0;
    animation: appear 3s 4.7s forwards;
  }
  
  .acosta {
    text-align: center;
    /* top: -13px; */
    left: 38px;
    margin-top: 0px;
    margin-bottom: 17px;
    color: white;
    text-shadow: 0 0 12px white;
    opacity: 0;
    animation: appear 3s 5.5s forwards;
  }
  
  .acosta, .david {
    position: relative;
    font-weight: lighter;
    font-size: 2.5rem;
  }

  @media (min-width: 350px) {
    .intro {
      text-align: center;
      max-width: 271px;
    }

    .acosta, .david {
      position: relative;
      font-weight: lighter;
      font-size: 2.7rem;
    }

    .david {
      right: 93px;
      margin-bottom: 0px;
    }

    .acosta {
      left: 56px;
    }



  }
  //--------------------------

  @media (min-width: 400px) {
    .intro {
      text-align: center;
      max-width: 287px;
    }

    .links-container {
      background-position-x: 218px;
    }

  }

  @media (min-width: 500px) {
    .header {
      margin-bottom: 30px;
    }
  }

  @media (min-width: 1025px) {
    .header {
      padding: 68px 30px;
      margin-top: 40px;
    }

    .hi {
      top: 175px;
    }
  }
