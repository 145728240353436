.project {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.project-info-container {
    width: 90%;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #313131cc;
    border: 2px solid #9a9a9a;
    border-radius: 9px;
    line-height: 1.7em;
    text-align: center;
}

.project-image {
    width: 78%;
    box-shadow: 5px 4px 5px black;
    border-radius: 18px;
    &.burger-app {
        width: auto;
        height: 230px;
    }

    &:hover {
        box-shadow:none;
        border: solid 1px rgba(78, 171, 246, 0.801);
        cursor: pointer;
    }
}

.project-summary {
    font-size: 1.2rem;
    font-weight: 100;
    text-align: left;
}

.project-title {
    font-weight: 300;
    font-size: 1.4rem;
}

.project-links a {
    text-decoration: none;
    color: #44b67c;
    font-weight: 600;
    padding: 8px;
    border: 1px solid white;
    border-radius: 12px;
    background-color: aliceblue;
    font-size: 1.5rem;
}

.hidden {
    display: none;
}

.tech-used {
    font-weight: 100;
    font-style: italic;
}

@media (min-width: 400px) {
    .project-image.burger-app {
        width: auto;
        height: 250px;
    }
}

@media (min-width: 600px) {
    .project-image.burger-app {
        width: auto;
        height: 285px;
    }

    .project-image.crown-clothing {
        width: auto;
        height: 250px;
    }

    .project-image.fox-game {
        width: auto;
        height: 250px;
    }

    .project-summary {
        font-size: 1.5rem;
        font-weight: 100;
        line-height: 2rem;
        text-align: left;
    }

    .tech-used {
        font-size: 1.4rem;
        font-weight: 100;
        font-style: italic;
    }

}

@media (min-width: 700px) {
    .project-title {
        font-weight: 300;
        font-size: 2.2rem;
    }

    .project-container-title {
        font-size: 60px;
    }

    .project {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 500px;
        border: solid 1px #ffffff40;
        background: linear-gradient(90deg, #8be5ff5c, #115467);
        margin-top: 30px;
        margin-bottom: 30px;
        height: 500px;
        border-radius: 100%;
    }

    .project-links a {
        font-size: 2.2rem;
    }
}

@media (min-width: 700px) {
    .project-margin {
        margin-bottom: 194px;

        &.mern {
            margin-bottom: 445px;
        }
    }


}