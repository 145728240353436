  div.drop-container {
    position: absolute;
    top: 33px;
    right: 0;
    left: 0;
    height: 200px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    overscroll-behavior-x: none;
  }
  
  div.drop {
    position: absolute;
    top: -25%;
    width: 100%;
    height: 100%;
    border-radius: 100% 5% 100% 100%;
    transform: rotate(-45deg);
    margin: 0px;
    background: rgb(255, 255, 255);
    animation: drip 4s forwards;
  }

  .speedup-drop {
    animation: drip 1s forwards;
  }
  
  .drop > div {
    position: relative;
    top: 0; left: 5px; right: 0; bottom: 0;
    text-align: center;
    opacity: 0;
    // animation: appear 8s 5.2s forwards;
  }
  
  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes disappear {
    0% {
        opacity: 0;
      }
      75% {
        opacity: 1;
      }
      100% {
          opacity: 0;
      }
  }
  
  div.drop-container:before,
  div.drop-container:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 55%;
    right: 50%;
    transform: translate(50%) rotateX(75deg);
    border-radius: 100%;
    opacity: 0;
    width: 75%;
    height: 75%;
    border: 5px solid skyblue;
    animation: dripple 2s ease-out 1s;
  }
  
  div.drop-container:after {
    animation: dripple 2s ease-out 1.7s;
  }
  
  @keyframes drip {
    45% {
      top: 0;
      border-radius: 100% 5% 100% 100%;
      transform: rotate(-45deg);
    }
    100% {
      top: 0;
      transform: rotate(0deg);
      border-radius: 100%;
    }
  }
  
  @keyframes dripple {
    0% {
      width: 150px;
      height: 150px;
    }
    25% {
      opacity: 1;
    }
    100% {
      width: 500px;
      height: 500px;
      top: -20%;
      opacity: 0;
    }
  }

  @media (min-width: 1025px) {
    div.drop-container {
      top: 108px;
  }
}