* {
  box-sizing: border-box;
}

.App {
  display: flex;
  flex-direction: column;
  /* padding: 32px 30px; */
  font-family: "Josefin Sans";
  width: 100%;
  height: 100%;
}

.skip {
  // animation: appear 1s 1s forwards;
  animation: none;
  opacity: 1;
  & h1,
  .intro,
  .avatar-container {
    animation: none;
    opacity: 1;
  }
  & .hi,
  .drop-container,
  .drop-container:before,
  .drop-container:after {
    animation: none;
    visibility: hidden;
  }
}
.hide {
  visibility: hidden;
}

main {
  // background: linear-gradient(180deg, #015871 39px, #474747 90%);
  background: linear-gradient(
    180deg,
    #015871 39px,
    #474747 90%,
    #ff4e00 99%,
    transparent
  );
  color: white;
  background-color: #015871;
  display: flex;
  flex-direction: column;
  /* padding: 32px 30px; */
  padding: 5px 12px;
  /* margin-top: 182px; */
  opacity: 0;
  z-index: 1;
  animation: appear 8s 8s forwards;
}

.pumpkin {
  background: transparent;
  position: absolute;
  color: #ffffff6b;
  border: #ffffff6b 1px groove;
  top: 18px;
  right: 14px;
  opacity: 0;
  animation: disappear 9s forwards;

  &:hover {
    color: #ffffffbf;
    text-shadow: 0 0 8px #0297ff;
    box-shadow: 0 0 8px 1px #01ceff;
    background-color: #f0f8ff47;
    border: #ffffff6b 1px groove;
  }
}

@media (min-width: 400px) {
  .main {
    padding: 5px 20px;
  }
}

@media (min-width: 500px) {
  .main {
    padding: 5px 50px;
  }
}

@media (min-width: 960px) {
  .main {
    padding: 5px 116px;
  }
}

@media (min-width: 1024px) {
  .main {
    padding: 5px 162px;
  }

  .pumpkin {
    top: 70px;
    left: 14px;
  }
}

// @media (max-width: 1300px) {
//   .main {
//     padding: 5px
//   }
// }
