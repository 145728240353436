.bubbles-container {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 50%;
    width: 100%;
    max-width: 15rem;
    transform: translateX(-50%);
      opacity: 0.75;
      overflow: visible;
  }
  
  .bubbles {
      width: 100%;
      height: auto;
  }
  
  circle {
    stroke: white;
    fill: none;
  }
  
  .bubbles > g > g:nth-of-type(3n) circle {
    stroke: #87f5fb;
  }
  
  .bubbles > g > g:nth-of-type(4n) circle {
    stroke: #8be8cb;
  }
  
  .bubbles-small {
      overflow: visible;
  }
  
  .bubbles-small > g {
    transform: translateY(2048px);
    will-change: transform, opacity;
  }
  
  .bubbles-small g:nth-of-type(1) circle {
    animation: wobble 2.3s infinite ease-in-out;
  }
  
  @keyframes wobble {
    33% {
      transform: translateX(-50px);
    }
  
    66% {
      transform: translateX(50px);
    }	
  }
  
  @keyframes up {
    0% {
      opacity: 0;
    }
  
    10%, 90% {
        opacity: 1;
    }
  
    100% {
        opacity: 0;
        transform: translateY(-124px);
    }
  }