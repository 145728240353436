
.contact-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    background: url(https://i.ibb.co/c8PBrXT/ezgif-com-gif-maker-3.gif);
    background-position-x: -35px;
    background-size: 189px;
    background-repeat: no-repeat;
    hr {
        margin-bottom: 50px;
        max-width: 600px;
    }
}



.contact-title {
    font-size: 46px;
    font-weight: 300;
    margin-bottom: 25px;
    font-style: normal;
}

.links-container {
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
    width: 150%;
    align-self: center;
    background: url(https://i.ibb.co/GJR2xk7/ezgif-com-gif-maker-2.gif), linear-gradient(180deg, #886e4e, #371a09);
    background-position-x: 169px;
    background-repeat: no-repeat, repeat;
}


.links {
    list-style: none;
    padding: 0;
    display: flex;
    width: 100%;
    margin-top: 40px;
    justify-content: space-evenly;
    max-width: 346px;
    margin-left: auto;
    display: flex;
    margin-right: auto;

    li {
        border: solid 1px white;
        background: linear-gradient(45deg, #484848, white);
        border-radius: 50%;
        width: 50px;
        display: flex;
        justify-content: center;
        height: 50px;
        align-items: center;
        box-shadow: 3px 14px 11px 0px #00000069;
    }
    .fa {
        transform: scale(2.5, 2.5);
        &.linked-in {
            color: rgb(0, 83, 139);
        }
        &.github {
            color: rgb(0, 83, 139);
        }
    }
}

.up-arrow-container {
    position: relative;
    /* padding: 30px; */
    display: flex;
    margin-top: 100px;
    margin-bottom: 40px;
    align-items: center;
    background-color: #9e9e9e;
    justify-content: center;
    padding-top: 3px;
    width: 69px;
    /* border: 1px solid black; */
    box-shadow: 0px 11px 20px 0px black;
    border-radius: 100%;
    overflow: hidden;
    height: 69px;
    margin-left: auto;
    margin-right: auto;
}

.up-arrow {
    transform: scale(4.5, 4.5);
    color: #00538b;
}

.info{
    margin-top: 30px;
}

.contact-email {
    color: white;
    text-decoration: none;
    font-size: 1.4rem;
    text-shadow: 0px 20px 13px black;
}


@media (min-width: 400px) {

    .links-container {
      background-position-x: 218px;
  }



  }

  @media (min-width: 500px) {
    .links-container {
        background-position-x: 286px;
    }

  }

  @media (min-width: 600px) {
    .links-container {
        background-position-x: 409px;
    }

    // .links {
    //     padding-left: 200px;
    //     padding-right: 200px;
    // }

    .contact-email {
        color: white;
        text-decoration: none;
        font-size: 2rem;
        text-shadow: 0px 20px 13px black;
    }

  }

  @media (min-width: 700px) {
    .links-container {
        background-position-x: 507px;
    }

    
    .links {

        li {
            border: solid 1px white;
            background: linear-gradient(45deg, #484848, white);
            border-radius: 50%;
            width: 80px;
            display: flex;
            justify-content: center;
            height: 80px;
            align-items: center;
            box-shadow: 3px 14px 11px 0px #00000069;
        }

        .fa {
            transform: scale(3, 3);
            &.linked-in {
                color: rgb(0, 83, 139);
            }
            &.github {
                color: rgb(0, 83, 139);
            }
        }
    }

    .info p {
        font-size: 1.2rem;
        margin-top: 50px;
    }
    
    .up-arrow {
        transform: scale(6,6);
        color: #00538b;
    }

    .up-arrow-container {
        margin-top: 123px;
        margin-bottom: 39px;
        width: 100px;
        height: 100px;
    }


  }

  @media (min-width: 820px) {
    .links-container {
        background-position-x: 667px;
    }

    // .links {
    //     padding-left: 300px;
    //     padding-right: 300px;
    // }

  }

  @media (min-width: 1024px) {
    .links-container {
        background-position-x: 719px;
        background-position-y: 128px, 0px;
    }

  }

  @media (min-width: 1300px) {
    .links-container {
        background-position-x: 1048px;
        background-position-y: 31px, 0px;
    }

  }

  @media (min-width: 1500px) {
    .links-container {
        background-position-x: 1348px;
        background-position-y: 31px, 0px;
    }

  }

  @media (min-width: 1600px) {
    .links-container {
        background-position-x: 1431px;
        background-position-y: 31px, 0px;
    }

  }

  @media (min-width: 1820px) {
    .links-container {
        background-position-x: 1731px;
        background-position-y: 31px, 0px;
    }

  }