.about-me-container {
    width:100%;
    display: flex;
    flex-direction: column;
    // border: solid 1px orange;
    text-align: center;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 140px;
    margin-bottom: 3em;
    // gap: 3em;

    hr {
        width: 70%;
        background-color: #9c9c9ca6;
        height: 4px;
        border: none;
        border-radius: 13px;
        color: black;
        margin-top: 10px;
        margin-bottom: 44px;
        max-width: 600px;
    }
}

.about-me-title {
    font-size: 46px;
    font-weight: 300;
    margin-bottom: 25px;
}



.about-me {
    margin-top: 5px;
    margin-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 1.4em;
    font-weight: 200;
    line-height: 1.5;
    text-align: left;
    max-width: 750px;
}

.adobe {
    color: #ff7500;
}


@media (min-width: 400px) {
    .links-container {
      background-position-x: 218px;
  }

}

@media (min-width: 600px) {
    .about-me {
        font-size: 1.7em;
    }

}

@media (min-width: 700px) {
    .about-me-title {
        font-size: 50px;

    }
}

@media (min-width: 1024px) {
    .about-me-container {
        padding-top: 30px;
    }
}