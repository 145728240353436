
.resume {
  width: 100%;
  position: relative;
  top: -59px;
  opacity: 0;
  margin-bottom: -72px;
}

.resume-bottom-div {
  background-color: #01313f;
  /* border: solid 1px red; */
  height: 300px;
  width: 100vw;
  /* bottom: 0px; */
  position: absolute;
  margin-top: -19px;
}


// text {
//    transform="rotate(30 20,40)
// }

// footer div {
//   background-color:#4478e3;
//   margin: -5px 0px 0px 0px;
//   padding:0px;
//   color: #fff;
//   text-align:center;
// }
.resume-svg {
  width:100%;
  height: fit-content; //change this as device gets wider
}
// .arrow {
//   stroke-width: .3px;
//   stroke:yellow;
// }

.ball {
  r: 8;
  transform: translate(4%, -17%);
  fill: #4e0404;
}

.resume-text {
  font-size: 0.4rem;
  font-weight: 100;
}

.topball {
  animation: ball 1.5s ease-in-out;
  animation-iteration-count:infinite;
  animation-direction: alternate;
  // animation-delay: 0.3s;
  animation-delay: 0.5s;
  cursor:pointer;
}



.wave-main {
  animation: waveish 3s linear;
  animation-iteration-count:infinite;
  fill: #01313f;
}

.gooeff {
  	filter: url(#goo);
}

@keyframes waveish {
  to {transform: translateX(-100%);}
}
@keyframes ball {
  to {transform: translateY(20%);}
}


@media (min-width: 500px) {

  .resume {
    margin-bottom: -58px;
  }

  .resume-text {
    font-size: 6.4px;

  }

}


@media (min-width: 600px) {

  .resume-svg {
    height: auto;
  }

  .ball {
    r: 5;
    transform: translate(4%, -4%);
}

  .resume-text {
    font-size: 4.7px;
    transform: translate(2%, 7%);
  }

}



@media (min-width: 820px) {

  .ball {
    r: 4.5;
    transform: translate(4%, -1%);
  }
  
  .resume-text {
    font-size: 3.4px;
    transform: translate(3.5%, 10%);
  }
  
}


@media (min-width: 1020px) {

  .ball {
    r: 4;
    transform: translate(4%, 3%);
  }
  
  .resume-text {
    font-size: 3px;
    transform: translate(4%, 12%);
  }
  
}

@media (min-width: 1300px) {
  
  .resume-text {
    font-size: 2.7px;
    transform: translate(4.35%, 12%);
  }
  
}

@media (min-width: 1600px) {
  
  .resume-text {
    font-size: 2.5px;
    transform: translate(4.5%, 15%);
  }
  
}

@media (min-width: 1800px) {

  .ball {
    r: 3.4;
    transform: translate(4%, 5%);
  }
  
  .resume-text {
    font-size: 2px;
    transform: translate(5.2%, 16%);
  }
  
}