.projects-container {
    width:100%;
    display: flex;
    flex-direction: column;
    // border: solid 1px orange;
    text-align: center;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 3em;
    gap: 3em;
}

.project-container-title {
    font-size: 46px;
    font-weight: 300;
    margin-bottom: 0px;
}

hr {
    width: 70%;
    background-color: #9c9c9ca6;
    height: 4px;
    border: none;
    border-radius: 13px;
    color: black;
    max-width: 600px;
}

.category-summary {
    margin-top: 0px;
    margin-top: 0px;
    font-size: 1.3rem;
    font-weight: 100;
    line-height: 1.3;
    max-width: 350px;
}

@media (min-width: 1025px) {
    .projects-container {
        padding-top: 43px;
    }
}