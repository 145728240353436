

.avatar-container {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  z-index: 11;
  position: relative;
  // bottom: -8px;
  max-width: 200px;
  max-height: 200px;
  min-width: 30px;
  min-height: 30px;
  opacity: 0;
  animation: appear 8s 5.2s forwards;
  }
  
  .avatar {
    z-index: -10;
    border-radius: 100%;
    max-width: 200px;
    max-height: 200px;
    min-width: 30px;
    min-height: 30px;
  
    box-shadow: 0 0 15px #f5f1f1, inset -40px 0px 0px #d8e8f7,
      inset 50px 0px 0px #ffffff, inset 68px 0px 0px 35px #e2eefa,
      0px 0px 0px 20px rgba(255, 255, 255, 0.05),
      0px 0px 0px 51px rgba(255, 255, 255, 0.034),
      0px 0px 0px 74px rgba(255, 255, 255, 0.04);
  }


/* swinging leg animation */
.thread {
    /* margin-right: auto; */
    /* margin-left: auto; */
    /* display: block; */
    width: 78px;
    /* z-index: 0; */
    bottom: -55px;
    left: 58px;
    height: 60px;
    background-size: contain;
    /* border-radius: 5px; */
    position: absolute;
    transform-origin: 50% 0;
    animation: moveIt 2.5s ease-in-out infinite;
    z-index: -2;
  }
  
  /* .thread:after {
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: red;
    border: 2px solid white;
    top: -4.5px;
    left: -3.5px;
  } */
  
  
  @keyframes moveIt {
    0%,
    100% {
      transform: rotate(15deg);
    }
    50% {
      transform: rotate(-15deg);
    }
  }
  
  // @keyframes moveShade {
  //   0% {
  //     transform: translateX(-220px) scale(1.4, 0.5);
  //     filter: blur(20px);
  //   }
  //   50% {
  //     filter: blur(3px);
  //   }
  //   100% {
  //     transform: translateX(220px) scale(1.4, 0.5);
  //     filter: blur(20px);
  //   }
  // }
  
  /* swinging leg animation */