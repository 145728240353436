nav {
  top: 22px;
  z-index: 101;
  position: fixed;
  left: 22px;
}

.modal {
  z-index: -1;
  background-color: #315254e0;
  width: 100vh;
  height: 112vh;
  top: 0px;
  left: 0px;
  position: absolute;

    // transform-origin: 0% 0%;
    // transform: translate(-100%, 0);
    
    // transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

#menuToggle
{
  display: block;
  position: relative;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle a
{
  text-decoration: none;
  color: #232323;
  
  transition: color 0.3s ease;
}

#menuToggle a:hover
{
  color: rgba(255, 99, 71, 0.931);
}


#menuToggle input
{
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  
  cursor: pointer;
  
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  
  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#menuToggle span
{
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  
  background: #cdcdcd;
  // border-radius: 3px;
  box-shadow: 5px 4px 20px black;
  border-radius: 18px;
  
  z-index: 1;
  
  transform-origin: 4px 0px;
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

#menuToggle span:first-child
{
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
  transform-origin: 0% 100%;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked ~ span
{
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked ~ span:nth-last-child(2)
{
  transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu
{
  position: absolute;
  width: 220px;
  margin: -100px 0 0 -50px;
  padding: 120px 4px 28px 50px;
  background: rgb(63 63 63 / 87%);
  border-radius: 13px;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

#menu li
{
  padding: 10px 0;
  font-size: 22px;
  font-weight: 100;
  color: white;

  &:hover {
    box-shadow: 3px 3px 8px 6px black;
    border-radius: 4px;
    padding-left: 5px;
  }
}

#menu a {
  z-index: 105;
}

/*
 * And let's slide it in from the left
 */
#menuToggle input:checked ~ ul
{
  transform: none;
}

@media (min-width: 1025px) {

  .mobile-nav {
    display: none;
  }

}